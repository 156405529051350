<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg show-footer-only-on-last-screen company-documents-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="about-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("notifications.notifications.notifications") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
          <router-link
            :to="{
              name: 'r_add-company-notification',
              params: $route.params,
            }"
            v-if="
              $route.name == 'r_company-notifications' &&
                can('MANAGE_NOTIFICATIONS', 'create')
            "
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>

      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="submitSearch"
        @removeSelectedFilter="removeSelectedFilter"
        :hasFilters="true"
        :selectedFilters="selectedFilters"
        :routeName="'r_company-documents-search-filters'"
      ></search>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <section>
      <div class="scroll"></div>
      <ul
        class="clebex-item-section pill"
        v-if="listOfNotifications && listOfNotifications.length"
      >
        <li
          class="clebex-item-section-item"
          v-for="(notification, index) in listOfNotifications"
          :key="index"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="editNotification(notification)"
            v-if="!selectMode"
          >
            <span class="label">
              <span class="highlight">{{ notification.name }} </span>
              <span> &nbsp;{{ notification.send_to_list }}</span>
            </span>
            <span
              class="follow-up-icons"
              v-if="can('MANAGE_NOTIFICATIONS', 'read')"
            >
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-information"></icon>
              </span>
            </span>
          </button>
          <div class="clebex-item-content-wrapper" v-else>
            <div class="checkbox tiny alt block">
              <input
                type="checkbox"
                :id="`notification${notification.id}`"
                name="notification"
                :selected="selectedNotifications.includes(notification.id)"
                :value="notification.id"
                @change="selectNotification(notification)"
              />
              <label :for="`notification${notification.id}`">
                <svg-icon icon="checkmark-strait"></svg-icon>
                <span class="highlight">
                  {{ notification.name }}
                </span>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: selectMode }"
              @click="setSelectMode"
            >
              {{ displayLabelName("global.footer.select") }}
            </button>
          </li>
          <li
            class="action"
            :style="deleteButtonStyle"
            v-if="can('MANAGE_NOTIFICATIONS', 'delete')"
          >
            <button class="action-btn" @click="showDialog">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedNotifications.length +
                "/" +
                selectedNotifications.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>

    <screen-modal
      v-if="showDeleteDialog"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteDialog"
      @close="showDeleteDialog = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("notifications.notifications.delete-title") }}
      </h3>
      <br />
      <p class="text">
        {{ displayLabelName("notifications.notifications.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
// import EditCompanyDocument from "./EditCompanyDocument.vue";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "CompanyNotifications",
  mixins: [paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search")),
  },
  data() {
    return {
      search: null,
      selectMode: false,
      selectedItem: null,
      showDeleteDialog: false,
      selectedNotifications: [],
    };
  },
  created() {
    this.submitSearch();
  },
  computed: {
    ...mapState("companyNotifications", ["companyNotifications"]),
    ...mapState("searchFilters", ["searchActiveNum"]),
    listOfNotifications() {
      const query = this.searchQuery;
      if (this.companyNotifications && this.companyNotifications.length) {
        if (query && query.length > 1) {
          return this.companyNotifications.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.companyNotifications;
        }
      }
      return null;
    },
    deleteButtonStyle() {
      const visibility =
        this.selectMode && this.selectedNotifications.length
          ? "visible"
          : "hidden";

      return {
        visibility,
      };
    },
    searchQuery: {
      get() {
        return this.$store.state.search.searchQuery;
      },
      set(value) {
        this.$store.commit("search/setSearchQuery", value, {
          root: true,
        });
      },
    },
  },
  watch: {
    searchActiveNum() {
      this.submitSearch();
    },
  },
  methods: {
    ...mapActions("companyNotifications", [
      "getCompanyNotifications",
      "deleteCompanyNotification",
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    editNotification(notification) {
      if (this.can("MANAGE_NOTIFICATIONS", "read")) {
        setTimeout(() => {
          this.$router.push({
            name: "r_edit-company-notification",
            params: { company_notification_id: notification.id },
          });
        }, 0);
      }
    },
    selectNotification(notification) {
      if (this.selectedNotifications.includes(notification.id)) {
        this.selectedNotifications.splice(
          this.selectedNotifications.indexOf(notification.id),
          1
        );
      } else {
        this.selectedNotifications.push(notification.id);
      }
    },
    toggleSearch() {
      this.search = !this.search;
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    handleSelection(id) {
      this.selectedItem = id;
    },
    clearSearchQuery() {
      this.searchQuery = "";
      this.$store.commit("search/setSearchQuery", "", {
        root: true,
      });
      this.submitSearch();
    },
    submitSearch() {
      this.getCompanyNotifications({
        search: this.searchQuery,
      });
    },
    removeSelectedFilter() {},
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedNotifications.length; i++) {
        await this.deleteCompanyNotification(this.selectedNotifications[i]);
      }

      this.setPaginationData(null);
      this.selectMode = false;
      this.selectedNotifications = [];
      this.getCompanyNotifications();
    },
    setSelectMode() {
      this.selectedItem = null;
      this.selectMode = !this.selectMode;
    },
    showDialog() {
      this.showDeleteDialog = true;
    },
    closeExportDialog() {
      this.showDeleteDialog = false;
    },
  },
  beforeUnmount() {
    this.$store.commit("search/setSearchQuery", "", {
      root: true,
    });
  },
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
